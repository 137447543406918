import React from 'react';
import Footer from '../components/Footer';
import EndInfoBanner from '../components/landing/EndInfoBanner';
import Hero from '../components/landing/Hero';
import Functions from '../components/landing/Functions';
import Steps from '../components/landing/Steps';
import Information from '../components/landing/Information';
import Seo from '../components/utils/Seo';

const Index = () => (
  <>
    <Hero/>
    <Information/>
    <Steps/>
    <Functions />
    <EndInfoBanner/>
    <Footer />
  </>
);

export const Head = ({ location }) => (
  <Seo location={location}/>
);


export default Index;
