import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
import landingBackgroundSmall from '../../images/landing_backgroundSmall.svg';

const PREFIX = 'Information';
const classes = {
  sectionTwo: `${PREFIX}-sectionTwo`,
  container: `${PREFIX}-container`,
  sectionTwoContent: `${PREFIX}-sectionTwoContent`,
  header: `${PREFIX}-header`,
  sectionTwoContentDiv: `${PREFIX}-sectionTwoContentDiv`,
};

const RootInformation = styled('div')(({ theme }) => ({
  [`& .${classes.sectionTwo}`]: {
    background: `url(${landingBackgroundSmall})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down('md')]: {
      background: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  [`& .${classes.container}`]: {
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  [`& .${classes.sectionTwoContent}`]: {
    textAlign: 'center',
    marginTop: '146px',
    color: '#2C2121',
    paddingTop: '20px',
    paddingBottom: '90px',
    [theme.breakpoints.down('xl')]: {
      paddingBottom: '40px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '30px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
      paddingBottom: '20px',
    },
  },
  [`& .${classes.header}`]: {
    fontSize: '65px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#2C2121',
    marginBottom: '12px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '60px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '44px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '35px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
  },
  [`& .${classes.sectionTwoContentDiv}`]: {
    fontSize: '24px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '40px',
    letterSpacing: '0.1em',
    marginTop: '54px',
    color: '#2C2121',
    [theme.breakpoints.down('xl')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '30px',
      marginTop: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      letterSpacing: '0.02em',
    },
  },
}));

const HeroBr700 = styled('br')(({ theme }) => ({
  [theme.breakpoints.down('700')]: {
    display: 'none',
  },
}));

const Information = () => (
  <RootInformation>
    <Box className={classes.sectionTwo}>
      <Container maxWidth='xl' className={classes.container}>
        <Box className={classes.sectionTwoContent}>
          <Typography variant='h1'  className={classes.header}>
          Bramka płatności i egzekwowanie należności
          </Typography>
          <Typography variant='h5' className={classes.sectionTwoContentDiv}>
            Serwis umożliwia <strong>egzekwowanie należności</strong> od
            dłużnika. Zapłatomat to także <HeroBr700 />
            wygodna bramka płatności dla osób bez działalności gospodarczej.{' '}
            <HeroBr700 />
            Zapłatomat wyśle <strong>
              uprzejme przypomnienie o płatności
            </strong>{' '}
            i udostępnia <HeroBr700 />
            płatnikom przelew natychmiastowy paybylink lub Blik. Ściąganie
            należności <HeroBr700 />
            staje się łatwiejsze, a Ty możesz łatwo sprawdzić,{' '}
            <strong>
              czy przelew został wysłany <HeroBr700 />
              na Twoje konto
            </strong>
            .
          </Typography>
        </Box>
      </Container>
    </Box>
  </RootInformation>
);

export default Information;
