import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from '@mui/material';
import landingPath from '../../images/landing_path.svg';
import landingPathRotate from '../../images/landing_path_rotate.svg';
import landingBackground from '../../images/landing_background.png';

const PREFIX = 'Steps';
const classes = {
  container: `${PREFIX}-container`,
  sectionThree: `${PREFIX}-sectionThree`,
  header: `${PREFIX}-header`,
  sectionThreeImage: `${PREFIX}-sectionThreeImage`,
  sectionThreeGrid: `${PREFIX}-sectionThreeGrid`,
  sectionThreeBox: `${PREFIX}-sectionThreeBox`,
  sectionThreeStep: `${PREFIX}-sectionThreeStep`,
  sectionThreeDiv: `${PREFIX}-sectionThreeDiv`,
};

const RootSteps = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  [`& .${classes.sectionThree}`]: {
    paddingLeft: '24px',
    paddingRight: '24px',
    textAlign: 'center',
    marginTop: '72px',
    [theme.breakpoints.down('md')]: {
      background: `url(${landingBackground})`,
      backgroundPosition: 'bottom',
      backgroundSize: 'cover',
    },
  },
  [`& .${classes.header}`]: {
    fontSize: '70px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#2C2121',
    marginBottom: '12px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '60px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '44px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '35px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
  },
  [`& .${classes.sectionThreeImage}`]: {
    display: 'flex',
    justifyContent: 'center',
    background: `url(${landingPath})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginTop: '70px',
    [theme.breakpoints.down('xl')]: {
      marginTop: '30px',
      marginLeft: '50px',
      marginRight: '50px',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '20px',
      marginLeft: '20px',
      marginRight: '20px',
    },
    [theme.breakpoints.down('md')]: {
      background: `url(${landingPathRotate})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginTop: '40px',
      height: '650px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '550px',
    },
  },
  [`& .${classes.sectionThreeGrid}`]: {
    justifyContent: 'center',
    alignItems: 'baseline',
    display: 'flex',
    marginTop: '100px',
    marginBottom: '100px',
    maxWidth: '1260px',
    [theme.breakpoints.down('xl')]: {
      maxWidth: '1150px',
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1000px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
      marginBottom: '0',
      alignContent: 'space-around',
    },
  },
  [`& .${classes.sectionThreeBox}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.sectionThreeStep}`]: {
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: 700,
    [theme.breakpoints.down('xl')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '19px',
    },
  },
  [`& .${classes.sectionThreeDiv}`]: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 400,
    color: '#000000',
    letterSpacing: '0.1em',
    textAlign: 'center',
    lineHeight: '40px',
    width: '285px',
    marginTop: '20px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '20px',
      lineHeight: '35px',
      width: '245px',
      marginTop: '15px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      lineHeight: '25px',
      width: '190px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '21px',
      width: '160px',
      marginTop: '10px',
      letterSpacing: '0.08em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      width: '135px',
    },
  },
}));

const HeroBr600 = styled('br')(({ theme }) => ({
  [theme.breakpoints.up('600')]: {
    display: 'none',
  },
}));

const Steps = () => (
  <RootSteps>
    <Container maxWidth='xl' className={classes.container}>
      <Box className={classes.sectionThree} textAlign='center'>
        <Typography component='h2' paragraph className={classes.header}>
          Jak działa Zapłatomat? <HeroBr600 />
          To proste!
        </Typography>

        <Box className={classes.sectionThreeImage}>
          <Grid container className={classes.sectionThreeGrid}>
            <Grid item xs={12} md={4}>
              <Box
                className={classes.sectionThreeBox}
                sx={{ marginRight: { sm: 0, md: '25px' } }}
              >
                <Typography
                  variant='h4'
                  className={classes.sectionThreeStep}
                  sx={{ color: '#E04C34' }}
                >
                  Krok 1
                </Typography>
                <Typography variant='h5' className={classes.sectionThreeDiv}>
                  W zapłatomacie tworzysz link, który wysyłasz do płatnika
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                className={classes.sectionThreeBox}
                sx={{ marginBottom: { xs: '18px', md: '0' } }}
              >
                <Typography
                  variant='h4'
                  className={classes.sectionThreeStep}
                  sx={{ color: '#F07D2D' }}
                >
                  Krok 2
                </Typography>
                <Typography variant='h5' className={classes.sectionThreeDiv}>
                  Płatnik otwiera link i płaci szybkim przelewem lub BLIKiem
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                className={classes.sectionThreeBox}
                sx={{ marginLeft: { sm: 0, md: '25px' } }}
              >
                <Typography
                  variant='h4'
                  className={classes.sectionThreeStep}
                  sx={{ color: '#E04C34' }}
                >
                  Krok 3
                </Typography>
                <Typography variant='h5' className={classes.sectionThreeDiv}>
                  Pieniądze trafiają na Twoje konto bankowe
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  </RootSteps>
);

export default Steps;
