import React from 'react';
import { Grid, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { Box } from '@mui/system';

const featureList = [
  {
    name: 'Link do płatności',
    subName: false,
    description: (
      <>
        Wygeneruj link do szybkiej płatności i wyślij go SMSem, komunikatorem
        lub emailem do osoby, która ma Ci zapłacić
      </>
    ),
    icon: (
      <StaticImage
        layout='fixed'
        quality={100}
        src='../../images/landingLink.svg'
        alt='landingLink'
      />
    ),
    iconSmall: (
      <StaticImage
        layout='fixed'
        quality={100}
        height={35}
        src='../../images/landingLink.svg'
        alt='landingLink'
      />
    ),
  },
  {
    name: 'Sposoby zapłaty',
    subName: false,
    description: (
      <>
        Odbiorca dostanie link do szybkiej płatności online, BLIK i dane do
        przelewu tradycyjnego
      </>
    ),
    icon: (
      <StaticImage
        layout='fixed'
        quality={100}
        src='../../images/landingCash.svg'
        alt='landingCash'
      />
    ),
    iconSmall: (
      <StaticImage
        layout='fixed'
        quality={100}
        height={35}
        src='../../images/landingCash.svg'
        alt='landingCash'
      />
    ),
  },

  {
    name: 'Przypomnienia',
    subName: false,
    description: (
      <>
        Wyślemy płatnikowi przypomnienia o terminie płatności, a Ciebie
        poinformujemy, kiedy zapłaci
      </>
    ),
    icon: (
      <StaticImage
        layout='fixed'
        quality={100}
        src='../../images/landingReminder.svg'
        alt='landingReminder'
      />
    ),
    iconSmall: (
      <StaticImage
        layout='fixed'
        quality={100}
        height={35}
        src='../../images/landingReminder.svg'
        alt='landingReminder'
      />
    ),
  },

  {
    name: 'Wezwanie do zapłaty ',
    subName: '(wkrótce)',
    description: (
      <>
        Jeżeli płatność nie została dokonana w terminie, pomożemy Ci formalnie
        wezwać dłużnika do zapłaty
      </>
    ),
    icon: (
      <StaticImage
        layout='fixed'
        quality={100}
        src='../../images/landingCall.svg'
        alt='landingCall'
      />
    ),
    iconSmall: (
      <StaticImage
        layout='fixed'
        quality={100}
        height={35}
        src='../../images/landingCall.svg'
        alt='landingCall'
      />
    ),
  },
  {
    name: 'Windykacja',
    subName: '(wkrótce)',
    description: (
      <>
        Możesz zdecydować się na skierowanie nieopłaconej w terminie płatności
        do windykacji przez naszych partnerów
      </>
    ),
    icon: (
      <StaticImage
        layout='fixed'
        quality={100}
        src='../../images/landingVindication.svg'
        alt='landingVindication'
      />
    ),
    iconSmall: (
      <StaticImage
        layout='fixed'
        quality={100}
        height={35}
        src='../../images/landingVindication.svg'
        alt='landingVindication'
      />
    ),
  },
  {
    name: 'Płatności cykliczne',
    subName: false,
    description: (
      <>
        Zdefiniuj powtarzalne płatności od Twoich klientów, a ZAPŁATOMAT
        dopilnuje aby były zapłacone. Idealne dla szkół, stowarzyszeń i
        rentierów
      </>
    ),
    icon: (
      <StaticImage
        layout='fixed'
        quality={100}
        src='../../images/landingCycle.svg'
        alt='landingCycle'
      />
    ),
    iconSmall: (
      <StaticImage
        layout='fixed'
        quality={100}
        height={35}
        src='../../images/landingCycle.svg'
        alt='landingCycle'
      />
    ),
  },
];

const Cards = () => {
  return (
    <Box
      sx={{
        marginX: { md: '20px', lg: '56px', xl: '76px' },
        marginTop: { md: '56px', lg: '56px', xl: '76px' },
      }}
    >
      <Grid container spacing={{ xs: 0, md: 2, lg: 2.5 }}>
        {featureList.map(({ icon, iconSmall, name, subName, description }) => (
          <Grid
            key={name}
            container
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
              mt: { xs: '10px', sm: '20px', md: 0 },
            }}
          >
            <Grid
              container
              item
              sx={{
                alignContent: { xs: 'center', md: 'space-between' },
                textDecoration: 'none',
                minHeight: { xs: '142px', sm: '115px' },
                color: 'white',
                borderRadius: { xs: '15px', md: '40px' },
                border: '1px solid rgba(0, 0, 0, 0.47)',
                py: { xs: '16px', md: '41px' },
                paddingLeft: { xs: '10px', md: '25px', lg: '26px', xl: '54px' },
                paddingRight: {
                  xs: '10px',
                  sm: '16px',
                  md: '25px',
                  lg: '26px',
                  xl: '54px',
                },
                '&:hover': {
                  boxShadow:
                    '-2px -2px 4px rgba(255, 255, 255, 0.22), 4px 4px 8px rgba(0, 0, 0, 0.62)',
                  cursor: 'default',
                  border: '1px solid #b8b8b8',
                  backgroundColor: 'rgba(255, 255, 255, 0.07)',
                },
              }}
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 0 }}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid item xs={4.5} sm={3} md={12}>
                  <Grid
                    item
                    xs={12}
                    color='secondary.main'
                    sx={{
                      paddingBottom: { xs: 0, md: '14px' },
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: 'none', md: 'flex' },
                        height: '60px',

                        justifyContent: 'center',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {icon}
                    </Box>

                    <Box
                      sx={{
                        display: { xs: 'flex', md: 'none' },
                        height: '40px',
                        justifyContent: 'center',
                        alignContent: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {iconSmall}
                    </Box>
                  </Grid>
                  <Grid item xs={12} textAlign='center'>
                    <Typography
                      component='h3'
                      color='text.primary'
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        marginTop: { xs: '3px', md: '18px' },
                        paddingBottom: { xs: 0, sm: '2px', md: '14px' },
                        fontSize: { xs: '12px', sm: '13px', md: '16px' },
                        lineHeight: { xs: '13px', md: 'normal' },
                      }}
                    >
                      {name}
                      {subName.length > 0 ? (
                        <Box
                          height='1px'
                          sx={{
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            fontSize: { xs: '10px', sm: '11px', md: '12px' },
                            marginTop: { xs: '1px', md: '-3px' },
                            marginBottom: { xs: '8px', md: '3px' },
                          }}
                        >
                          {subName}
                        </Box>
                      ) : null}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={7.5} sm={9} md={12} color='text.primary'>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 400,
                      marginTop: { xs: '0', md: '15px' },
                      marginLeft: { xs: '5px', sm: '10px', md: '15px' },
                      marginRight: { xs: 0, sm: '10px', md: '15px' },
                      fontSize: {
                        xs: '12px',
                        sm: '13px',
                        md: '14px',
                        lg: '15px',
                        xl: '16px',
                      },
                      textAlign: { xs: 'left', md: 'center' },
                      lineHeight: {
                        xs: '18px',
                        sm: '20px',
                        lg: '22.5px',
                        xl: '24px',
                      },
                      letterSpacing: { xs: '-0.01em', sm: '0' },
                    }}
                  >
                    {description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Cards;
