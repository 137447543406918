import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const Seo = ({children, location, title, description}) => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
                ogImage,
                twitterSite,
                siteUrl
              }
            }
          }
        `
      )
      const metaDescription = description || site.siteMetadata?.description;
      const metaTitle = title  ? `${title} | Zapłatomat` : site.siteMetadata?.title;
      
    return (
      <>
        <title>{metaTitle}</title>
        <meta property="og:url" content={`${site.siteMetadata.siteUrl}${location?.pathname}`}/>
        <meta name='description' content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${site.siteMetadata.siteUrl}/${site.siteMetadata.ogImage}`}/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={site.siteMetadata.author} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={`${site.siteMetadata.siteUrl}/${site.siteMetadata.ogImage}`} />        
        <meta name="twitter:site" content={site.siteMetadata.twitterSite}/>
        <meta name='robots' content={process.env.GATSBY_ENVIRONMENT === 'production' ? 'index, follow' : 'noindex, nofollow'}/>
        {children}
    </>)
}

Seo.defaultProps = {
  title: "",
  description: ""
}

export default Seo;