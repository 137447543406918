import React, { useState } from 'react';
import { Drawer, Box, IconButton, Divider, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { navigate } from 'gatsby-link';

export const TopMenuLanding = () => {
  const [isDrawerOpen, setisDrawerOpen] = useState(false);

  return (
    <>
      <IconButton
        size='large'
        edge='start'
        sx={{ color: '#F07D2D' }}
        aira-label='logo'
        onClick={() => setisDrawerOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={() => setisDrawerOpen(false)}
      >
        <Box p={2} textAlign='center'>
          <Button
            sx={{ fontSize: '14px', color: 'black', fontFamily: 'Poppins' }}
            onClick={() => navigate('/user/sign-in')}
          >
            Zaloguj się
          </Button>
          <Divider />
        </Box>
      </Drawer>
    </>
  );
};
