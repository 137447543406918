import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { ArrowForwardIos } from '@mui/icons-material';
import { navigate } from 'gatsby-link';

const PREFIX = 'EndInfoBanner';
const classes = {
  sectionFive: `${PREFIX}-sectionFive`,
  sectionFiveDivOne: `${PREFIX}-sectionFiveDivOne`,
  sectionFiveDivTwo: `${PREFIX}-sectionFiveDivTwo`,
  sectionFiveDivThree: `${PREFIX}-sectionFiveDivThree`,
  sectionFiveDivFour: `${PREFIX}-sectionFiveDivFour`,
  sectionFiveButton: `${PREFIX}-sectionFiveButton`,
  container: `${PREFIX}-container`,
};

const RootEndInfoBanner = styled('div')(({ theme }) => ({
  [`& .${classes.sectionFive}`]: {
    paddingTop: '88px',
    paddingBottom: '88px',
    textAlign: 'center',
    position: 'relative',
    boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.15)',
    paddingLeft: '100px',
    paddingRight: '100px',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: '80px',
      paddingRight: '0px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '40px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '30px',
      paddingTop: '60px',
      paddingBottom: '60px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '35px',
      paddingBottom: '70px',
    },
  },
  [`& .${classes.container}`]: {
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  [`& .${classes.sectionFiveDivOne}`]: {
    fontSize: '33px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    letterSpacing: '0.02em',
    color: '#2C2121',
    marginBottom: '0px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xl')]: {
      fontSize: '30px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '19px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  [`& .${classes.sectionFiveDivTwo}`]: {
    fontSize: '55px',
    fontFamily: 'Poppins',
    fontWeight: 300,
    color: '#2C2121',
    marginTop: '7px',
    marginBottom: '0px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '50px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '31px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
    },
  },
  [`& .${classes.sectionFiveDivThree}`]: {
    fontSize: '103px',
    fontFamily: 'Poppins',
    fontWeight: 100,
    letterSpacing: '0.03em',
    color: '#2C2121',
    marginTop: '-7px',
    marginBottom: '0px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '94px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '79px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '61px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '49px',
    },
  },
  [`& .${classes.sectionFiveDivFour}`]: {
    fontSize: '24px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '40px',
    letterSpacing: '0.08em',
    color: '#2C2121',
    marginTop: '39px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      marginTop: '29px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
      lineHeight: '26px',
      marginBottom:'14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '26px',
    },
  },
  [`& .${classes.sectionFiveButton}`]: {
    height: '42px',
    width: '346px',
    borderRadius: '8px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    marginTop: '59px',
    lineHeight: '26px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '29px',
    },
    [theme.breakpoints.down('md')]: {
      height: '36px',
      width: '322px',
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      width: '260px',
      fontSize: '14px',
    },
  },
}));

const HeroBr900 = styled('br')(({ theme }) => ({
  [theme.breakpoints.down('900')]: {
    display: 'none',
  },
}));

const EndInfoBanner = () => (
  <RootEndInfoBanner>
    <Box className={classes.sectionFive}>
      <Container maxWidth='xl' className={classes.container}>
        <Grid container justifyContent='center' alignItems='center' spacing={2}>
          <Grid item xs={12} sm={7} md={6}>
            <Typography
              variant='h3'
              className={classes.sectionFiveDivOne}
              paragraph
            >
              Klient lub znajomy zapomniał Ci
            </Typography>
            <Typography
              variant='h3'
              className={classes.sectionFiveDivTwo}
              paragraph
            >
              zapłacić lub zwrócić
            </Typography>
            <Typography
              variant='h3'
              className={classes.sectionFiveDivThree}
              paragraph
            >
              pieniądze?
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Typography variant='h6' className={classes.sectionFiveDivFour}>
                Utwórz i wyślij link do szybkiej płatności, <br />a Zapłatomat
                odświeży mu pamięć ;)
              </Typography>
              <AnimationOnScroll animateIn='animate__bounceIn' delay={200}>
                <Button
                  className={classes.sectionFiveButton}
                  variant='contained'
                  onClick={() => navigate('/payment')}
                  disableElevation
                  color='secondary'
                >
                  Zacznij korzystać z Zapłatomatu
                  <ArrowForwardIos
                    fontSize='18px'
                    sx={{ marginLeft: '16px' }}
                  />
                </Button>
              </AnimationOnScroll>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={6}
            sx={{
              paddingBottom: { xs: '0', sm: '0', md: '20px', lg: '0' },
              maxWidth: { xs: '300px', sm: 'none' },
              paddingLeft: '60px',
            }}
          >
            <StaticImage
              src='../../images/landing_alarm.svg'
              quality={100}
              alt='Zapłatomat'
              // width={400}
              placeholder='none'
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            paddingRight: { sm: '30px' },
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h6' className={classes.sectionFiveDivFour}>
            Utwórz i wyślij link do szybkiej płatności, <HeroBr900 /> a
            Zapłatomat odświeży mu pamięć ;)
          </Typography>
          <AnimationOnScroll animateIn='animate__bounceIn' delay={20}>
            <Button
              className={classes.buttonEnd}
              variant='contained'
              onClick={() => navigate('/payment')}
              disableElevation
              color='secondary'
            >
              Zacznij korzystać z Zapłatomatu
              <ArrowForwardIos
                fontSize='18px'
                sx={{
                  marginLeft: { xs: '6px', sm: '10px', md: '16px' },
                }}
              />
            </Button>
          </AnimationOnScroll>
        </Box>
      </Container>
    </Box>
  </RootEndInfoBanner>
);

export default EndInfoBanner;
