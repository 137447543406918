import React from 'react';
import {Box, Container, Divider, Grid, IconButton, Typography,} from '@mui/material';
import Link from '../commons/Link';
import {Link as GatsbyLink} from 'gatsby';
import {Facebook, GitHub, Telegram, Twitter} from '@mui/icons-material';
import {StaticImage} from 'gatsby-plugin-image';

const socialLinks = [
  {
    icon: <Facebook />,
    href: 'https://www.facebook.com/fiberpay.payment.services',
  },
  { icon: <Twitter />, href: 'https://twitter.com/fiberpay' },
  { icon: <GitHub />, href: 'https://github.com/fiberpay/fiberpay-php' },
  { icon: <Telegram />, href: 'https://t.me/zaplatomat_pl' },
];

const SocialLinksGrid = () => (
  <Box
    display='flex'
    sx={{ justifyContent: { xs: 'center', sm: 'right', md: 'right' } }}
  >
    {socialLinks.map(({ icon, href }) => (
      <Box
        key={href}
        m={1}
        component='a'
        href={href}
        target='_blank'
        rel='noopener noreferrer'
      >
        <IconButton sx={{ color: 'black' }}>{icon}</IconButton>
      </Box>
    ))}
  </Box>
);

const Footer = ({maxWidth="xl", paddingX="100px"}) => (
  <>
    <Box sx={{ backgroundColor: '#FAFAFA' }}>
      <Container
        maxWidth={maxWidth}
        sx={{
          fontFamily: 'Poppins',

          paddingTop: { xs: '40px', sm: '40px', md: '50px', xl: '60px' },
          paddingBottom: { xs: '20px', sm: '30px', md: '50px' },
          paddingX: {xs: "20px", sm: "30px", md: "50px", lg: {paddingX}, xl: {paddingX}}
        }}
      >
        <Grid container item xs={12}>
          <Grid container item xs={7.5} sm={5} md={6.4} lg={6}>
            <Grid
              item
              xs={12}
              sx={{
                fontWeight: 'bold',
                height: '36px',
                fontSize: { xs: '12px', sm: '14px', md: '16px' },
              }}
            >
              FiberPay sp. z o.o.
            </Grid>
            <Grid container>
              <Grid xs={12} lg={5} xl={5} item sx={{ paddingBottom: '10px' }}>
                <Typography
                  sx={{
                    fontSize: {xs: '12px', sm: '14px', md: '16px'},
                    fontFamily: 'Poppins',
                  }}
                >
                  ul. Sienna 86/47
                </Typography>
                <Typography
                  sx={{
                    fontSize: {xs: '12px', sm: '14px', md: '16px'},
                    fontFamily: 'Poppins',
                  }}
                >
                  00-815 Warszawa
                </Typography>
                <Typography
                  sx={{
                    fontSize: {xs: '12px', sm: '14px', md: '16px'},
                    fontFamily: 'Poppins',
                  }}
                >
                  <a href='mailto:pomoc@zaplatomat.pl' className='link-inline'>
                    pomoc@zaplatomat.pl
                  </a>
                </Typography>
                <Typography
                  sx={{
                    fontSize: {xs: '12px', sm: '14px', md: '16px'},
                    fontFamily: 'Poppins',
                  }}
                >
                  <a href='tel:+48 22 230 26 22' className='link-inline'>
                    +48 22 230 26 22
                  </a>
                </Typography>
              </Grid>
              <Grid
                xs={12}
                lg={5}
                item
                sx={{ marginTop: { xs: '0', lg: 0 } }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                    fontFamily: 'Poppins',
                  }}
                >
                  NIP: 7010634566
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                    marginRight: '14px',
                    fontFamily: 'Poppins',
                  }}
                >
                  REGON: 36589948900000
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                    fontFamily: 'Poppins',
                  }}
                >
                  KRS: 0000647662
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4.5}
            sm={6}
            md={5.6}
            lg={3}
            direction='column'
          >
            <Grid
              container
              item
              sx={{
                fontWeight: 'bold',
                height: '36px',
                fontSize: { xs: '12px', sm: '14px', md: '16px' },
              }}
            >
              Informacje
            </Grid>
            <Grid container item justifyContent='space-between'>
              <Grid
                item
                xs={12}
                md={12}
                xl={12}
                sx={{
                  marginBottom: { xs: '0', sm: '0', md: '0' },
                  marginTop: { xs: '-2px', sm: '-2px', md: 0 },
                  lineHeight: { xs: '18px', sm: 'normal' },
                }}
              >
                <Link
                  component={'a'}
                  href='https://fiberpay.pl/'
                  sx={{
                    fontSize: { xs: '12px', sm: '14px', md: '16px' },
                  }}
                >
                  FiberPay - płatności online
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                xl={12}
                sx={{
                  paddingRight: { xs: 0, xl: '16px' },
                  lineHeight: { xs: '18px', sm: 'normal' },
                }}
              >
                <Link
                  component={GatsbyLink}
                  to='/polityka-prywatnosci/'
                  sx={{
                    textAlign: { xl: 'right' },
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      md: '16px',
                    },
                  }}
                >
                 Polityka prywatności
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                xl={12}
                sx={{
                  paddingRight: { xs: 0, xl: '16px' },
                  lineHeight: { xs: '18px', sm: 'normal' },
                }}
              >
                <Link
                  component={GatsbyLink}
                  to='/regulamin/'
                  sx={{
                    textAlign: { xl: 'right' },
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      md: '16px',
                    },
                  }}
                >
                 Regulaminy
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                xl={12}
                sx={{
                  paddingRight: { xs: 0, xl: '16px' },
                  lineHeight: { xs: '18px', sm: 'normal' },
                }}
              >
                <Link
                  component={GatsbyLink}
                  to='/contact/'
                  sx={{
                    textAlign: { xl: 'right' },
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      md: '16px',
                    },
                  }}
                >
                  Kontakt
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            direction='column'
          >
            <Grid
              container
              item
              sx={{
                marginTop: {xs: "25px", lg: "0"},
                fontWeight: 'bold',
                height: {xs: "26px", lg: "36px"},
                fontSize: { xs: '12px', sm: '14px', md: '16px' },
              }}
            >
              Narzędzia
            </Grid>
            <Grid container item justifyContent='space-between'>
            <Grid
                item
                xs={12}
                md={12}
                xl={12}
                sx={{
                  paddingRight: { xs: 0, xl: '16px' },
                  lineHeight: { xs: '18px', sm: 'normal' },
                }}
              >
                <Link
                  component={GatsbyLink}
                  to='/kalkulator-odsetek-ustawowych/'
                  sx={{
                    textAlign: { xl: 'right' },
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      md: '16px',
                    },
                  }}
                >
                  Kalkulator odsetek ustawowych
                </Link>
              </Grid> 
            </Grid>
            </Grid>
        </Grid>

        <Grid
          xs={12}
          item
          sx={{ marginTop: { xs: '24px', sm: '32px', md: '32px', lg: '64px' } }}
        >
          <Typography sx={{ fontSize: { xs: '10px', sm: '11px', md: '12px' } }}>
            Fiberpay jest instytucją płatniczą nadzorowaną przez KNF i wpisaną
            do rejestru małych instytucji płatniczych: MIP28/2019
          </Typography>
        </Grid>

        <Divider
          sx={{
            marginBottom: { xs: '20px', sm: '12px', md: 0 },
            marginTop: { xs: '12px' },
          }}
        ></Divider>

        <Grid container item xs={12} sx={{ alignItems: 'center' }}>
          <Grid
            item
            sm={5}
            md={3.4}
            lg={2.9}
            xl={2}
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            <GatsbyLink to='/'>
              <StaticImage
                src='../images/logo_zaplatomat.svg'
                alt='Zapłatomat'
                width={175}
                placeholder='none'
              />
            </GatsbyLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={8.6}
            lg={9.1}
            xl={10}
            sx={{
              marginTop: { xs: 1, sm: 0 },
              display: 'flex',
              justifyContent: { xs: 'center', md: 'right' },
            }}
          >
            <SocialLinksGrid />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Footer;
