import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
import Cards from './Cards';

const PREFIX = 'Functions';
const classes = {
  sectionFour: `${PREFIX}-sectionFour`,
  header: `${PREFIX}-header`,
  subHeader: `${PREFIX}-subHeader`,
};

const RootFunctions = styled('div')(({ theme }) => ({
  [`& .${classes.sectionFour}`]: {
    textAlign: 'center',
    marginTop: '164px',
    paddingBottom: '64px',
    [theme.breakpoints.down('xl')]: {
      marginTop: '80px',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '8px',
      marginRight: '8px',
      marginTop: '60px',
      paddingBottom: '32px',
    },
  },
  [`& .${classes.header}`]: {
    fontSize: '70px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#2C2121',
    marginBottom: '12px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '60px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '44px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '35px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
  },
  [`& .${classes.subHeader}`]: {
    fontSize: '42px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    marginTop: '10px',
    color: '#2C2121',
    [theme.breakpoints.down('xl')]: {
      fontSize: '38px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
}));

const Functions = () => (
  <RootFunctions>
    <Container maxWidth='xl'>
      <Box className={classes.sectionFour}>
        <Typography variant='h2' className={classes.header}>
          Funkcje Zapłatomatu
        </Typography>
        <Typography variant='h3' className={classes.subHeader}>
          Jak nasza aplikacja pomoże Ci odzyskać należności?
        </Typography>
        <Cards />
      </Box>
    </Container>
  </RootFunctions>
);

export default Functions;
