import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Container,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import { navigate } from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { ArrowForwardIos } from '@mui/icons-material';
import { TopMenuLanding } from './../topmenu/TopMenuLanding';
import LandingBackground from './../images/LandingBackground';
import { GatsbyLink } from 'gatsby-theme-material-ui';

const PREFIX = 'Hero';
const classes = {
  sectionFirst: `${PREFIX}-sectionFirst`,
  container: `${PREFIX}-container`,
  toolbar: `${PREFIX}-toolbar`,
  buttonLogin: `${PREFIX}-buttonLogin`,
  buttonLoginText: `${PREFIX}-buttonLoginText`,
  sectionFirstContent: `${PREFIX}-sectionFirstContent`,
  herotext: `${PREFIX}-herotext`,
  herotext2: `${PREFIX}-herotext2`,
  herotext3: `${PREFIX}-herotext3`,
  buttonRegister: `${PREFIX}-buttonRegister`,
  bold: `${PREFIX}-bold`,
  imageContainer: `${PREFIX}-imageContainer`,
};

const RootHero = styled('div')(({ theme }) => ({
  [`& .${classes.sectionFirst}`]: {
    color: 'white',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: 0,
  },

  [`& .${classes.container}`]: {
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  [`& .${classes.toolbar}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '60px',
    paddingBottom: '132px',
    paddingLeft: '97px',
    paddingRight: '97px',
    [theme.breakpoints.down('xl')]: {
      paddingTop: '40px',
      paddingLeft: '74px',
      paddingRight: '74px',
      paddingBottom: '60px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: '25px',
      paddingLeft: '34px',
      paddingRight: '38px',
      paddingBottom: '68px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '25px',
      paddingLeft: '23px',
      paddingRight: '36px',
      paddingBottom: '68px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '19px',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingBottom: '40px',
    },
  },

  [`& .${classes.buttonLogin}`]: {
    fontWeight: '500',
    height: '42px',
    width: '149px',
    borderRadius: '8px',
    [theme.breakpoints.down('md')]: {
      height: '36px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  [`& .${classes.buttonLoginText}`]: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '15px',
    letterSpacing: '0.46px',
    paddingRight: '7px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  [`& .${classes.sectionFirstContent}`]: {
    alignItems: 'flex-start',
    paddingRight: '24px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  [`& .${classes.herotext}`]: {
    fontFamily: 'Poppins',
    fontSize: '83px',
    fontWeight: '600',
    lineHeight: 1.05,
    letterSpacing: '-0.05em',
    color: '#2C2121',
    paddingBottom: '52px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '71px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '52px',
      paddingBottom: '33px',
      lineHeight: 1.16,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      paddingBottom: '23px',
    },
  },
  [`& .${classes.herotext2}`]: {
    fontFamily: 'Poppins',
    whiteSpace: 'nowrap',
    fontSize: '80px',
    fontWeight: '400',
    color: '#2C2121',
    letterSpacing: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '69px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '52px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '39px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '31px',
    },
  },
  [`& .${classes.herotext3}`]: {
    whiteSpace: 'nowrap',
    fontSize: '24px',
    fontFamily: ' Poppins',
    fontWeight: '400',
    color: '#2C2121',
    letterSpacing: '0.1em',
    lineHeight: '40px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '23px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      letterSpacing: '0.04em',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '35px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      letterSpacing: '0.03em',
      lineHeight: '30px',
      whiteSpace: 'normal',
    },
  },
  [`& .${classes.buttonRegister}`]: {
    height: '42px',
    width: '236px',
    borderRadius: '8px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    marginTop: '80px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '60px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      height: '36px',
      width: '194px',
      marginBottom: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      height: '30px',
      width: '175px',
      marginBottom: 0,
      marginTop: '40px',
    },
  },
  [`& .${classes.bold}`]: {
    fontWeight: '900',
    color: '#2C2121',
  },

  [`& .${classes.imageContainer}`]: {
    textAlign: 'center',
    paddingTop: '16px',
    [theme.breakpoints.down('xl')]: {
      paddingTop: '110px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '40px',
      paddingLeft: '20px',
      paddingRight: '20px',
      maxWidth: '350px',
    },
  },
}));

const HeroBr400 = styled('br')(({ theme }) => ({
  [theme.breakpoints.down('600')]: {
    display: 'none',
  },
}));

const Hero = () => (
  <RootHero>
    <LandingBackground>
      <Box className={classes.sectionFirst}>
        <Container maxWidth='xl' className={classes.container}>
          <Toolbar className={classes.toolbar}>
            <Box sx={{ width: { xs: '135px', sm: '200px' } }}>
              <GatsbyLink to='/'>
                <StaticImage
                  src='../../images/logo_zaplatomat.svg'
                  alt='Zapłatomat'
                  quality={100}
                  placeholder='none'
                />
              </GatsbyLink>
            </Box>
            <Box>
              <Button
                className={classes.buttonLogin}
                variant='contained'
                onClick={() => navigate('/user/sign-in')}
              >
                <Typography className={classes.buttonLoginText}>
                  Zaloguj się &nbsp;
                </Typography>
                <ArrowForwardIos fontSize='10px' />
              </Button>
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <TopMenuLanding />
              </Box>
            </Box>
          </Toolbar>
          <Grid
            container
            className={classes.sectionFirstContent}
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={12} sm={6} md={5.8} lg={6} xl={5.5}>
              <Box
                sx={{
                  paddingLeft: {
                    xs: '23px',
                    sm: '33px',
                    md: '42px',
                    lg: '82px',
                    xl: '98px',
                  },
                  paddingRight: { xs: '23px', sm: 0 },
                }}
              >
                <Typography variant='h1' className={classes.herotext} paragraph>
                  Przypominamy <br />
                  <span className={classes.herotext2}>o płatnościach</span>
                </Typography>
                <Typography variant='h5' className={classes.herotext3}>
                  W <span className={classes.bold}>Zapłatomacie</span>{' '}
                  wygenerujesz płatności <HeroBr400 />
                  online dla Twoich klientów, a system <HeroBr400 />
                  <span className={classes.bold}>przypomni</span> im o terminie
                  zapłaty
                </Typography>
                <Grid
                  container
                  sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
                >
                  <AnimationOnScroll animateIn='animate__bounceIn' delay={200}>
                    <Button
                      className={classes.buttonRegister}
                      variant='contained'
                      onClick={() => navigate('/payment')}
                      color='secondary'
                    >
                      WYPRÓBUJ TERAZ!
                      <ArrowForwardIos
                        fontSize='18px'
                        sx={{
                          marginLeft: { xs: '6px', sm: '10px', md: '16px' },
                        }}
                      />
                    </Button>
                  </AnimationOnScroll>
                </Grid>
              </Box>
            </Grid>

            <Grid
              item
              sm={6}
              className={classes.imageContainer}
              sx={{
                display: { xs: 'none', sm: 'block', md: 'none' },
              }}
            >
              <StaticImage
                src='../../images/landing_photo_small.svg'
                alt='Zaplatomat application'
                placeholder='none'
                quality={100}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6.2}
              lg={6}
              xl={6.5}
              sx={{ display: { xs: 'block', sm: 'none', md: 'block' } }}
              className={classes.imageContainer}
            >
              <StaticImage
                src='../../images/landing_photo.svg'
                alt='Zaplatomat application'
                placeholder='none'
                quality={100}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LandingBackground>
  </RootHero>
);

export default Hero;
